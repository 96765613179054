<template>
  <section
    class="
      w-full
      mx-auto
      bg-yellow-600
      flex
      pt-12
      md:pt-0 md:items-center
      bg-center
      bg-local
      bg-cover
    "
    style="
      max-width: 1600px;
      height: 18rem;
      background-image: url('img/bgbgbgg.jfif');
    "
  >
    <div class="container mx-auto">
      <div
        class="
          flex flex-col
          w-full
          lg:w-1/2
          justify-center
          items-start
          px-6
          tracking-wide
        "
      >
        <h1
          class="
            text-white
            bg-black
            rounded-2xl
            p-2
            bg-opacity-20
            text-3xl
            my-4
            sm:mt-0
            -mt-8
          "
        >
          Bizi Sosyal Medyada Takip Edin !
        </h1>
        <div class="mt-20">
        <SMButtons />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import SMButtons from "@/components/share/SocialMediaButtons";
export default {
  name: "TopSection",
  components: {
    SMButtons,
  },
};
</script>

<style></style>
